import styled from "styled-components/macro";
import React, { useEffect, useState } from "react";
import {
  MdArrowBackIos,
  MdArrowForwardIos,
  MdCancel,
  MdDelete,
  MdImage,
  MdMenu,
} from "react-icons/md";

import "../styles/Products.css";
import "../styles/Sidebar.css";
import { useLocation, useNavigate } from "react-router";
import { isEmpty } from "../functions/isEmpty";

export default function Content({
  isMobile,
  products,
  apiURL,
  reloadProducts,
  setReloadProducts,
  page,
  variationProducts,
  setVariationProducts,
}) {
  const location = useLocation();
  let navigate = useNavigate();
  const [activeID, setActiveID] = useState("");

  const [title, setTitle] = useState("");
  const [bilder, setBilder] = useState([]);
  const [social_trigger, setSocial_trigger] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  const [mediaImageURL, setMediaImageURL] = useState("");
  const [imageUploads, setImageUploads] = useState([]);

  const setActiveElement = (element) => {
    setActiveID(element.id);
    setTitle(element.title);

    let newArray = [];
    JSON.parse(element.bilder).map((pic) => newArray.push(pic.url));
    setBilder(newArray);
    setSocial_trigger(element.social_trigger);
    setMediaImageURL(JSON.parse(element.bilder)[0].url);
    setImageUploads([]);
  };

  useEffect(() => {
    if (!isEmpty(products) && !isEmpty(activeID)) {
      for (let i = 0; i < products.length; i++) {
        if (products[i].id == activeID) {
          let newArray = [];
          JSON.parse(products[i].bilder).map((pic) => newArray.push(pic.url));
          setBilder(newArray);
        }
      }
    }
  }, [products]);

  const handleUpload = async (imgURL) => {
    if (isLoading) {
      return;
    }
    setIsLoading(true);

    let pics = [];
    bilder.map((img) => pics.push({ url: img }));
    if (!isEmpty(imgURL)) {
      pics.push({ url: imgURL });
    }
    const resa = await fetch(
      `${apiURL}/api/content_letsfluence/index.php?id=` + activeID,
      {
        method: "POST",
        headers: {
          //   Authorization: `Bearer ${authToken?.JWT}`,
          "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
        },
        body: JSON.stringify({
          bilder: JSON.stringify(pics),
          title: title,
          social_trigger: social_trigger,
        }),
      }
    );

    await resa.text().then((value) => uploadEnds(value));
  };

  const addNewProduct = async () => {
    if (isLoading) {
      return;
    }
    setIsLoading(true);

    const resa = await fetch(`${apiURL}/api/content_letsfluence/index.php`, {
      method: "PUT",
      headers: {
        //   Authorization: `Bearer ${authToken?.JWT}`,
        "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
      body: JSON.stringify({
        title: "New Content",
        social_trigger: social_trigger,
      }),
    });

    await resa.text().then((value) => uploadEnds(value));
  };

  const duplicateProduct = async () => {
    if (isLoading) {
      return;
    }
    setIsLoading(true);

    let pics = [];
    bilder.map((img) => pics.push({ url: img }));

    const resa = await fetch(`${apiURL}/api/content_letsfluence/index.php`, {
      method: "PUT",
      headers: {
        //   Authorization: `Bearer ${authToken?.JWT}`,
        "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
      body: JSON.stringify({
        bilder: JSON.stringify(pics),
        title: "Kopie von " + title,
        social_trigger: social_trigger,
      }),
    });

    await resa.text().then((value) => uploadEnds(value));
  };

  const uploadEnds = (value) => {
    setIsLoading(false);
    setReloadProducts(!reloadProducts);
    if (isMobile) {
      setActiveID("");
    }

    if (value != "") {
      setImageUploads([]);
    }
  };

  useEffect(() => {
    var productList = document.getElementById("productList");
    var productDetail = document.getElementById("productDetail");

    if (productList != null && productDetail != null) {
      if (isMobile && activeID == "") {
        productDetail.classList.add("d-none");
        productList.classList.remove("d-none");
      } else if (isMobile) {
        productDetail.classList.remove("d-none");
        productList.classList.add("d-none");
      } else {
        productList.classList.remove("d-none");
        productDetail.classList.remove("d-none");
      }
    }
  }, [isMobile, activeID]);

  const interest_type = [""];

  const social_triggers = ["gefroren", "frisch", ""];

  const insertLocalImage = (e) => {
    if (e.target.files.length !== 0) {
      let file = e.target.files[0];
      let filetype = "image";
      let newArray = [];
      imageUploads.map((imgObj) => newArray.push(imgObj));
      newArray.push({
        localURL: URL.createObjectURL(file),
        files: file,
        filetype: filetype,
      });
      setImageUploads(newArray);
      setMediaImageURL(URL.createObjectURL(file));
    } else {
      return;
    }

    return;
  };

  const handleUploadForImage = async () => {
    setIsLoading(true);

    const files = document.getElementById("imgInp").files;
    const formData = new FormData();
    if (!isEmpty(imageUploads)) {
      setMediaImageURL(imageUploads[0].localURL);
    }

    for (let i = 0; i < files.length; i++) {
      let file = files[i];

      formData.append("files[]", file);
    }

    if (files.length != 0 && !isEmpty(imageUploads)) {
      const res = await fetch(`${apiURL}/api/images/process.php`, {
        headers: {
          //  Authorization: `Bearer ${authToken?.JWT}`,
        },
        method: "POST",
        body: formData,
      });

      await res.text().then((value) => handleUpload(value));
    } else {
      handleUpload();
    }
  };

  const removeImage = () => {
    let newArray = [];
    for (let i = 0; i < bilder.length; i++) {
      if (bilder[i] != mediaImageURL) {
        newArray.push(bilder[i]);
      }
    }
    setBilder(newArray);
  };

  const moveImage = (direction) => {
    let searchString = mediaImageURL;
    const array = [];
    bilder.map((img) => array.push(img));
    const index = array.indexOf(searchString);
    // Check if the searchString is present in the array
    if (index === -1) {
      console.error("String not found in array");
      return array;
    }

    // Check if the searchString is not at the start or end of the array
    if (
      (index > 0 && direction === -1) ||
      (index < array.length - 1 && direction === 1)
    ) {
      // Swap the elements
      const temp = array[index];
      array[index] = array[index + direction];
      array[index + direction] = temp;

      setBilder(array);
    } else {
      console.error("Cannot move element beyond array bounds");
      return array;
    }
  };

  const resetImageUpload = () => {
    setImageUploads([]);
    setMediaImageURL(bilder[0]);
  };

  return (
    <div className="page-divider">
      <div className="sidebar">
        <div className="sidebar_top">
          {!isMobile && (
            <div className="" style={{ paddingLeft: "14px" }}>
              Promo
            </div>
          )}

          {!isMobile ? (
            <>
              {location.pathname.substring(0, 6) == "/" ? (
                <div className="sidebar_top_navpoint_active">Letsfluence</div>
              ) : (
                <div
                  className="sidebar_top_navpoint"
                  onClick={() => navigate("/")}>
                  Letsfluence
                </div>
              )}
              {location.pathname.substring(0, 7) == "/orders" ? (
                <div className="sidebar_top_navpoint_active"> Grillchef</div>
              ) : (
                <div
                  className="sidebar_top_navpoint"
                  onClick={() => navigate("/orders")}>
                  Grillchef
                </div>
              )}
            </>
          ) : (
            <>
              {activeID == "" && (
                <div className="topnav">
                  <div className="sidebar_top">
                    {location.pathname.substring(0, 6) == "/" ? (
                      <div className="sidebar_top_navpoint_active">
                        Letsfluence
                      </div>
                    ) : (
                      <div
                        className="sidebar_top_navpoint"
                        onClick={() => navigate("/")}>
                        Letsfluence
                      </div>
                    )}
                    {location.pathname.substring(0, 7) == "/grillchef" ? (
                      <div className="sidebar_top_navpoint_active">
                        {" "}
                        Grillchef
                      </div>
                    ) : (
                      <div
                        className="sidebar_top_navpoint"
                        onClick={() => navigate("/grillchef")}>
                        Grillchef
                      </div>
                    )}
                  </div>
                </div>
              )}
            </>
          )}
        </div>
        <div className="sidebar_bottom"></div>
      </div>
      <div className="products">
        <div className="products_left" id="productList">
          <div className="products_list">
            <div className="products_addNew">
              <div
                className="products_addNew_button"
                onClick={() => duplicateProduct()}>
                Duplicate
              </div>
              <div
                className="products_addNew_button"
                onClick={() => addNewProduct()}>
                + Add New Content
              </div>
            </div>
            <div
              className=""
              style={{
                paddingLeft: "14px",
                paddingRight: "14px",
                paddingBottom: "14px",
              }}>
              Core Value: Jeder kann von zuhause Business machen
            </div>
            {products.map((product) => (
              <>
                {product.id == activeID ? (
                  <>
                    <div className="products_list_element_active">
                      <div className="products_list_element_col">
                        {product.id}
                      </div>
                      <div className="products_list_element_col">
                        <img
                          src={JSON.parse(product.bilder)[0].url}
                          style={({ width: "40px" }, { height: "40px" })}
                        />
                      </div>
                      <div className="products_list_element_col">
                        {product.title}
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div
                      className="products_list_element"
                      onClick={() => setActiveElement(product)}>
                      <div className="products_list_element_col">
                        {product.id}
                      </div>
                      <div className="products_list_element_col">
                        <img
                          src={JSON.parse(product.bilder)[0].url}
                          style={({ width: "40px" }, { height: "40px" })}
                        />
                      </div>
                      <div title="products_list_element_col">
                        {product.title}
                      </div>
                    </div>
                  </>
                )}
              </>
            ))}
          </div>
        </div>
        <div className="products_right" id="productDetail">
          {activeID != "" && (
            <>
              {isMobile ? (
                <div className="topnav">
                  <div className="" onClick={() => setActiveID("")}>
                    <LeftIcon /> Zurück
                  </div>
                  {isLoading ? (
                    <div className="savebtn_loading">...Lädt</div>
                  ) : (
                    <div
                      className="savebtn"
                      onClick={() => handleUploadForImage()}>
                      Speichern
                    </div>
                  )}
                </div>
              ) : (
                <div className="topnav">
                  {isLoading ? (
                    <div className="savebtn_loading">...Lädt</div>
                  ) : (
                    <div
                      className="savebtn"
                      onClick={() => handleUploadForImage()}>
                      Speichern
                    </div>
                  )}
                </div>
              )}

              {!isEmpty(imageUploads) ? (
                <div className="">
                  <img
                    className="createService_inputBtn_img"
                    style={{ width: "calc(100% - 24px)" }}
                    src={mediaImageURL}
                  />
                </div>
              ) : (
                <>
                  <img
                    className="createService_inputBtn_img"
                    style={{ width: "calc(100% - 24px)" }}
                    src={mediaImageURL}
                  />
                </>
              )}
              <div className="creator_previewimages">
                {isEmpty(imageUploads) && (
                  <label htmlFor={"imgInp"} className="">
                    <div className="createService_inputlabel">
                      <ImageIcon /> + Upload
                    </div>
                  </label>
                )}

                {bilder.map((creatorimg) => (
                  <>
                    <div
                      className="creator_previewimages_imgbox"
                      onClick={() => setMediaImageURL(creatorimg)}>
                      <img
                        src={creatorimg}
                        className={
                          mediaImageURL == creatorimg
                            ? "creator_previewimages_imgbox_img_active"
                            : "creator_previewimages_imgbox_img"
                        }
                      />
                      {mediaImageURL == creatorimg && bilder.length > 1 && (
                        <div className="creator_previewimages_imgbox_nav">
                          <div
                            className="creator_previewimages_imgbox_nav_btn"
                            onClick={() => removeImage()}>
                            <DeleteIcon />
                          </div>
                          <div
                            className="creator_previewimages_imgbox_nav_btn"
                            onClick={() => moveImage(-1)}>
                            <LeftIcon />
                          </div>
                          <div
                            className="creator_previewimages_imgbox_nav_btn"
                            onClick={() => moveImage(1)}>
                            <RightIcon />
                          </div>
                        </div>
                      )}
                    </div>
                  </>
                ))}
                {imageUploads.map((creatorimg) => (
                  <>
                    <div
                      className="creator_previewimages_imgbox"
                      onClick={() => setMediaImageURL(creatorimg.localURL)}>
                      <img
                        className={
                          mediaImageURL == creatorimg.localURL
                            ? "creator_previewimages_imgbox_img_active"
                            : "creator_previewimages_imgbox_img"
                        }
                        src={creatorimg.localURL}
                      />
                      <div className="creator_previewimages_imgbox_nav">
                        <div className="createService_inputBtn_cancelBtn">
                          <CancelIcon onClick={() => resetImageUpload()} />
                        </div>
                      </div>
                    </div>
                  </>
                ))}
              </div>
              <div className="inputrow">
                <input
                  type="file"
                  accept=".jpg, .jpeg, .png"
                  id={"imgInp"}
                  name="files[]"
                  className="createService_inputBtn d-none"
                  onChange={(e) => insertLocalImage(e)}
                />
              </div>

              <div className=""> title</div>
              <div className="inputrow">
                <input
                  type="text"
                  className="inputrow_input"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                />
              </div>

              <div className=""> Social Trigger</div>
              <div className="inputrow">
                <select
                  onChange={(e) => setSocial_trigger(e.target.value)}
                  className="inputrow_input">
                  {social_triggers.map((trigger) => (
                    <>
                      {social_trigger == trigger && (
                        <option value={trigger} selected>
                          {trigger}
                        </option>
                      )}
                      <option value={trigger}>{trigger}</option>
                    </>
                  ))}
                </select>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

const MenuIcon = styled(MdMenu)`
  font-size: 33px;
  color: #575757;
`;

const ArrowBack = styled(MdArrowBackIos)`
  font-size: 22px;
  color: #575757;
  margin-top: -1px;
  margin-left: 10px;
`;

const CancelIcon = styled(MdCancel)`
  font-size: 33px;
  color: #575757;
`;
const DeleteIcon = styled(MdDelete)`
  font-size: 33px;
  color: #575757;
`;

const ImageIcon = styled(MdImage)`
  font-size: 33px;
  color: #575757;
`;

const LeftIcon = styled(MdArrowBackIos)`
  font-size: 22px;
  color: #575757;
  margin-top: -1px;
  margin-left: 10px;
`;

const RightIcon = styled(MdArrowForwardIos)`
  font-size: 22px;
  color: #575757;
  margin-top: -1px;
  margin-left: 10px;
`;
